import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const requiredIfValidator =
  (
    controlName: string,
    isRequiredFnc: (formGroup: FormGroup) => boolean,
    errorMessage: { message: string } | boolean | null,
    allowEmptyValue = false
  ): ValidatorFn =>
  (formGroup: AbstractControl) => {
    if (!(formGroup instanceof FormGroup)) {
      return null;
    }

    const formControl = formGroup.get(controlName);
    if (isRequiredFnc(formGroup) && (allowEmptyValue || !formControl?.value)) {
      addError(formGroup, [controlName], 'required_if', errorMessage);
    } else {
      removeError(formGroup, [controlName], 'required_if');
    }

    return null;
  };

export const matchValidator =
  (
    controlName: string,
    matchToControlName: string,
    errorMessage: { message: string } | boolean | null,
    showErrorOnBoth = false
  ): ValidatorFn =>
  (formGroup: AbstractControl) => {
    if (!(formGroup instanceof FormGroup)) {
      return null;
    }

    const formControl = formGroup.get(controlName);
    const matchToFormControl = formGroup.get(matchToControlName);
    const showErrorOnControls = [controlName];
    if (showErrorOnBoth) {
      showErrorOnControls.push(matchToControlName);
    }
    if (formControl?.value !== matchToFormControl?.value) {
      addError(formGroup, showErrorOnControls, 'no_match', errorMessage);
    } else {
      removeError(formGroup, showErrorOnControls, 'no_match');
    }

    return null;
  };

const addError = (
  formGroup: FormGroup,
  controlNames: string[],
  errorKey: string,
  errorValue: { message: string } | boolean | null = true
): void => {
  for (const controlName of controlNames) {
    const control = formGroup.controls[controlName];
    const errors = control.errors || {};
    errors[errorKey] = errorValue;
    control.setErrors(errors);
    control.markAsDirty();
  }
};

const removeError = (formGroup: FormGroup, controlNames: string[], errorKey: string): void => {
  for (const controlName of controlNames) {
    const control = formGroup.controls[controlName];
    let errors: ValidationErrors | null = control.errors || {};
    if (errors && Object.keys(errors).indexOf(errorKey) !== -1) {
      delete errors[errorKey];
      if (Object.keys(errors).length === 0) {
        errors = null;
      }
      control.setErrors(errors);
    }
  }
};
