/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ZvExceptionMessageExtractor } from '@zvoove/components/core';

@Injectable({ providedIn: 'root' })
export class ZvExceptionMessageExtractorExtension extends ZvExceptionMessageExtractor {
  /**
   * Extracts a error message from a given error object
   *
   * @param error The error object.
   * @returns The error message
   */
  public override extractErrorMessage(error: unknown): string {
    // Helps the compatibility of the DataSources
    if (error && typeof error === 'object' && 'errorObject' in error) {
      error = error.errorObject;
    }
    if (error instanceof HttpErrorResponse) {
      if (error.status === 404) {
        return $localize`:@@error.resourceNotFound:Die von Ihnen gesuchte Ressource konnten wir nicht finden.`;
      }
      if (error.status === 413) {
        return $localize`:@@error.requestTooLarge:Die Anfrage, die Sie gesendet haben, ist zu groß. Falls Ihre erfassten Daten Bilder enthalten versuchen Sie, diese zu verkleinern.`;
      }
      let errorMsg: string = error.error?.title;
      if (errorMsg && typeof error.error.errors === 'object') {
        for (const key of Object.keys(error.error.errors)) {
          errorMsg += ' ' + (key ? key + ': ' : '') + '(' + error.error.errors[key].join(' ') + ')';
        }
      }
      return errorMsg || $localize`:@@error.unknownErrorMsg:Ein unerwarteter Fehler ist aufgetreten`;
    }
    return super.extractErrorMessage(error) ?? '';
  }
}
