import { ErrorHandler } from '@angular/core';
import { Subject, throttleTime } from 'rxjs';

export class GlobalErrorHandler extends ErrorHandler {
  private chunkLoadError$ = new Subject<void>();
  constructor() {
    super();
    this.chunkLoadError$.pipe(throttleTime(10 * 1000)).subscribe(() => {
      if (
        confirm(
          $localize`:@@error.chunkLoad: Es scheint ein Problem beim Laden einiger Teile unserer Anwendung zu geben. Dies könnte auf ein Netzwerkproblem oder ein Update unseres Systems zurückzuführen sein. Möchten Sie die Seite neu laden, um es erneut zu versuchen? Bitte beachten Sie, dass nicht gespeicherte Änderungen verloren gehen könnten.`
        )
      ) {
        window.location.reload();
      }
    });
  }

  override handleError(error: unknown) {
    console.error(error);
    if (error instanceof Error && error.message.match(/fetch|load|import/i) && error.message.match(/chunk|module/i)) {
      this.chunkLoadError$.next();
      return;
    }
    const errorJson = this.stringify(error);
    // Since the 'errorJson' already is JSON, we need to parse it first.
    const body = JSON.stringify({ error: JSON.parse(errorJson) as unknown, url: document.URL });
    void this.postError(body);
  }

  stringify(value: unknown) {
    switch (typeof value) {
      case 'string':
      case 'object':
        if (value instanceof Error) {
          // Error object has no enumerable properties, so we have to specify which properties to serialize
          // otherwise we just get "{}"
          return JSON.stringify(value, Object.getOwnPropertyNames(value));
        }
        return JSON.stringify(value);
      default:
        return String(value);
    }
  }

  async postError(body: string) {
    try {
      const response = await fetch(`${document.baseURI}client_error_trace`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: body,
      });

      if (!response.ok) {
        const result = await response.text();
        console.error('Error logging seemed to fail:', result);
      }
    } catch (e) {
      console.error('Error while logging error:', e);
    }
  }
}
