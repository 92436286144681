/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginTwoStepDto } from '../dto/login-two-step-dto';
import { LoginTwoStepResultDto } from '../dto/login-two-step-result-dto';

export interface PostAccountLoginTwoStepParams {
  body: LoginTwoStepDto;
}

export function httpPostAccountLoginTwoStep(http: HttpClient, data: PostAccountLoginTwoStepParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<LoginTwoStepResultDto>;
export function httpPostAccountLoginTwoStep(http: HttpClient, data: PostAccountLoginTwoStepParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<LoginTwoStepResultDto>>;
export function httpPostAccountLoginTwoStep(http: HttpClient, data: PostAccountLoginTwoStepParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<LoginTwoStepResultDto>(`{placeholder}/Account/LoginTwoStep`, data.body, options as any);
};

export const urlPostAccountLoginTwoStep = (baseUrl: string, data: PostAccountLoginTwoStepParams) => {
  return `${baseUrl}/Account/LoginTwoStep`;
};
