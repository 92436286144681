/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toQueryString } from '../../api-helpers';

export interface PostAccountSetLanguageQueryParams {
  culture?: string;
}

export interface PostAccountSetLanguageParams {
  query: PostAccountSetLanguageQueryParams;
}

export function httpPostAccountSetLanguage(http: HttpClient, data: PostAccountSetLanguageParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<void>;
export function httpPostAccountSetLanguage(http: HttpClient, data: PostAccountSetLanguageParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<void>>;
export function httpPostAccountSetLanguage(http: HttpClient, data: PostAccountSetLanguageParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<void>(`{placeholder}/Account/SetLanguage?${toQueryString(data.query)}`, undefined, options as any);
};

export const urlPostAccountSetLanguage = (baseUrl: string, data: PostAccountSetLanguageParams) => {
  return `${baseUrl}/Account/SetLanguage?${toQueryString(data.query)}`;
};
