/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SyncEntraUserAndGetAvdLoginUrlRequestDto } from '../dto/sync-entra-user-and-get-avd-login-url-request-dto';
import { SyncEntraUserAndGetAvdLoginUrlResponseDto } from '../dto/sync-entra-user-and-get-avd-login-url-response-dto';

export interface PostBffAvdSyncEntraUserAndGetAvdLoginUrlParams {
  body: SyncEntraUserAndGetAvdLoginUrlRequestDto;
}

export function httpPostBffAvdSyncEntraUserAndGetAvdLoginUrl(http: HttpClient, data: PostBffAvdSyncEntraUserAndGetAvdLoginUrlParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<SyncEntraUserAndGetAvdLoginUrlResponseDto>;
export function httpPostBffAvdSyncEntraUserAndGetAvdLoginUrl(http: HttpClient, data: PostBffAvdSyncEntraUserAndGetAvdLoginUrlParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<SyncEntraUserAndGetAvdLoginUrlResponseDto>>;
export function httpPostBffAvdSyncEntraUserAndGetAvdLoginUrl(http: HttpClient, data: PostBffAvdSyncEntraUserAndGetAvdLoginUrlParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<SyncEntraUserAndGetAvdLoginUrlResponseDto>(`{placeholder}/bff/avd/syncEntraUserAndGetAvdLoginUrl`, data.body, options as any);
};

export const urlPostBffAvdSyncEntraUserAndGetAvdLoginUrl = (baseUrl: string, data: PostBffAvdSyncEntraUserAndGetAvdLoginUrlParams) => {
  return `${baseUrl}/bff/avd/syncEntraUserAndGetAvdLoginUrl`;
};
