import { Injectable } from '@angular/core';
import { LoginContextDto } from '@zvoove-sso/api';
import { getInitialLoginContext } from '../bootstrap/bootstrap';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoginContextService {
  public backendUrl = environment.backendBaseUrl;
  public context = getInitialLoginContext();

  public hasGlobalRole(role: string): boolean {
    return this.contextIncludesGlobalRole(this.context, role);
  }

  public isLoggedIn(): boolean {
    return this.context.isLoggedIn;
  }

  public deploymentEnvironment(): string {
    return this.context.deploymentEnvironment;
  }

  public defaultRedirectUrl(): string {
    return this.context.defaultRedirectUrl;
  }

  public userName(): string | null | undefined {
    return this.context.userName;
  }

  public userId(): string | null | undefined {
    return this.context.userId;
  }

  private contextIncludesGlobalRole(context: LoginContextDto, role: string): boolean {
    return context.globalRoles.includes(role);
  }
}
