/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export function httpPostAccountLogout(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<void>;
export function httpPostAccountLogout(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<void>>;
export function httpPostAccountLogout(http: HttpClient, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<void>(`{placeholder}/Account/Logout`, undefined, options as any);
};

export const urlPostAccountLogout = (baseUrl: string) => {
  return `${baseUrl}/Account/Logout`;
};
