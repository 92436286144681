/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export function httpGetBffAvdUserHasRole(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<boolean>;
export function httpGetBffAvdUserHasRole(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<boolean>>;
export function httpGetBffAvdUserHasRole(http: HttpClient, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<boolean>(`{placeholder}/bff/avd/userHasRole`, options as any);
};

export const urlGetBffAvdUserHasRole = (baseUrl: string) => {
  return `${baseUrl}/bff/avd/userHasRole`;
};
