import { HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, tap } from 'rxjs';
import { DialogWrapperDataOut } from '../data-source/dialog-wrapper-data-source';
import { ConfirmDialog, ConfirmDialogDataIn } from '../dialog/confirm.dialog';

export const _timeGiver = {
  nowMs: () => new Date().getTime(),
};

export interface VersionCheckInterceptorOptions {
  appVersion: string;
  getNewestAppVersion: () => Observable<string>;
  throttleMs: number;
}

export const versionCheckInterceptor = (options: VersionCheckInterceptorOptions) => {
  if (!options.appVersion) {
    throw new Error(`Version is required.`);
  }

  let matDialog: MatDialog | null;
  const appVersion = normalizeVersion(options.appVersion);
  let lastMissmatchTime: number | null = null;

  function normalizeVersion(version: string): string {
    return version.trim().toLocaleLowerCase();
  }

  function checkVersion(res: HttpEvent<unknown>): void {
    if (!(res instanceof HttpResponse)) {
      return;
    }
    const serverVersionStr = normalizeVersion(res.headers.get('X-API-Version') ?? '');
    if (!serverVersionStr || serverVersionStr == appVersion) {
      return;
    }

    if (lastMissmatchTime != null && _timeGiver.nowMs() - lastMissmatchTime < options.throttleMs) {
      return;
    }

    // Set it imidatelly here so that we prevent multiple version checks at the same time.
    lastMissmatchTime = _timeGiver.nowMs();
    options.getNewestAppVersion().subscribe((newestAppVersion) => {
      // Set it also here, because we just checked the version, so we don't need to check it again for 'delay time' amount.
      lastMissmatchTime = _timeGiver.nowMs();

      newestAppVersion = normalizeVersion(newestAppVersion);
      if (newestAppVersion != appVersion) {
        matDialog
          ?.open<ConfirmDialog, ConfirmDialogDataIn, DialogWrapperDataOut<Observable<void>>>(ConfirmDialog, {
            data: {
              title: $localize`:@@error.versionMismatchTitle:Veraltete Version`,
              content: $localize`:@@error.versionMismatchContent:Wir haben festgestellt, dass Sie eine veraltete Version benutzen. Das kann zu Fehlern oder gar Abstürzen führen. Möchten Sie die Seite neu laden? Bitte beachten Sie, dass dabei eventuelle getätigte Eingaben nicht mit übernommen werden können!`,
              actionFn: () => of(window.location.reload()),
            },
          })
          .afterClosed()
          .subscribe(() => {
            // Set it also here, because the user decided to ignore it. We don't want to bother him immediately again.
            lastMissmatchTime = _timeGiver.nowMs();
          });
      }
    });
  }

  return (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    if (!matDialog) {
      // We need to do this here, because here we are in an injection context. Outside of this handler we aren't.
      matDialog = inject(MatDialog);
    }
    return next(req).pipe(tap(checkVersion));
  };
};
