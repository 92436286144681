/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toQueryString } from '../../api-helpers';
import { LoggedOutDto } from '../dto/logged-out-dto';

export interface GetAccountGetLoggedOutInformationQueryParams {
  logoutId?: string;
}

export interface GetAccountGetLoggedOutInformationParams {
  query: GetAccountGetLoggedOutInformationQueryParams;
}

export function httpGetAccountGetLoggedOutInformation(http: HttpClient, data: GetAccountGetLoggedOutInformationParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<LoggedOutDto>;
export function httpGetAccountGetLoggedOutInformation(http: HttpClient, data: GetAccountGetLoggedOutInformationParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<LoggedOutDto>>;
export function httpGetAccountGetLoggedOutInformation(http: HttpClient, data: GetAccountGetLoggedOutInformationParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<LoggedOutDto>(`{placeholder}/Account/GetLoggedOutInformation?${toQueryString(data.query)}`, options as any);
};

export const urlGetAccountGetLoggedOutInformation = (baseUrl: string, data: GetAccountGetLoggedOutInformationParams) => {
  return `${baseUrl}/Account/GetLoggedOutInformation?${toQueryString(data.query)}`;
};
