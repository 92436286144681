const supportedUnits = ['B', 'KB', 'MB', 'GB', 'TB'];

export const convertFilesize = (input: number, currentUnit: string, targetUnit?: string, appendUnit = true): string => {
  let currentUnitIndex = getCurrentUnitIndex(currentUnit);
  if (currentUnitIndex === -1) {
    return input + '';
  }

  const targetUnitIndex = getTargetUnitIndex(targetUnit);
  if (targetUnitIndex === -1) {
    while (currentUnitIndex === 0 || (input >= 1024 && currentUnitIndex < supportedUnits.length - 1)) {
      input /= 1024;
      currentUnitIndex++;
    }
  } else if (targetUnitIndex < currentUnitIndex) {
    while (currentUnitIndex > targetUnitIndex) {
      input *= 1024;
      currentUnitIndex--;
    }
  } else {
    while (currentUnitIndex < targetUnitIndex) {
      input /= 1024;
      currentUnitIndex++;
    }
  }

  return Math.round(input) + (appendUnit ? ' ' + supportedUnits[currentUnitIndex] : '');
};

const getCurrentUnitIndex = (currentUnit: string) => supportedUnits.indexOf(currentUnit.toUpperCase());

const getTargetUnitIndex = (targetUnit?: string) => {
  if (!targetUnit) {
    return -1;
  }
  return supportedUnits.indexOf(targetUnit.toUpperCase());
};
