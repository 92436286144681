/* eslint-disable */

export const enum EntraUserSyncResultStatus {
  success = 1,
  invalidCredentials = 2,
  isLockedOut = 3,
  doesNotHaveAvdRole = 4,
  graphApiCallFailed = 5,
  unknownError = 6,
  userNoLongerExists = 7,
}
