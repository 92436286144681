/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toQueryString } from '../../api-helpers';
import { AccountDetailsForRegistrationResultDto } from '../dto/account-details-for-registration-result-dto';

export interface GetAccountGetDetailsForRegistrationQueryParams {
  inviteCode?: string;
}

export interface GetAccountGetDetailsForRegistrationParams {
  query: GetAccountGetDetailsForRegistrationQueryParams;
}

export function httpGetAccountGetDetailsForRegistration(http: HttpClient, data: GetAccountGetDetailsForRegistrationParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<AccountDetailsForRegistrationResultDto>;
export function httpGetAccountGetDetailsForRegistration(http: HttpClient, data: GetAccountGetDetailsForRegistrationParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<AccountDetailsForRegistrationResultDto>>;
export function httpGetAccountGetDetailsForRegistration(http: HttpClient, data: GetAccountGetDetailsForRegistrationParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<AccountDetailsForRegistrationResultDto>(`{placeholder}/Account/GetDetailsForRegistration?${toQueryString(data.query)}`, options as any);
};

export const urlGetAccountGetDetailsForRegistration = (baseUrl: string, data: GetAccountGetDetailsForRegistrationParams) => {
  return `${baseUrl}/Account/GetDetailsForRegistration?${toQueryString(data.query)}`;
};
