import { inject } from '@angular/core';
import { LoginContextService } from '../login-context/login-context.service';
import { RedirectService } from '../public-api';

export const dependencies = { location: window.location };

export const alreadyLoggedInGuard = () => {
  const auth = inject(LoginContextService);
  const returnUrlService = inject(RedirectService);

  if (auth.isLoggedIn()) {
    const redirectUrl = dependencies.location.search.split('returnUrl=')[1];
    returnUrlService.navigateToReturnUrl(redirectUrl ? decodeURIComponent(redirectUrl) : auth.defaultRedirectUrl());
  }

  return !auth.isLoggedIn();
};
