import { IZvException } from '@zvoove/components/core';
import { IZvViewDataSource } from '@zvoove/components/view';
import { Observable, Subject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

export interface ViewDataSourceOptions<TParams, TData> {
  loadTrigger$: Observable<TParams>;
  loadFn: (params: TParams) => Observable<TData>;
  keepLoadStreamOpen?: boolean;
}

export class ViewDataSource<TParams, TData> implements IZvViewDataSource {
  public exception: IZvException | null = null;

  private loading = false;
  private hasLoadError = false;
  private blockView = false;
  private stateChanges$ = new Subject<void>();

  private loadingSub = Subscription.EMPTY;
  private connectSub = Subscription.EMPTY;

  constructor(private options: ViewDataSourceOptions<TParams, TData>) {}

  public get contentVisible(): boolean {
    return !this.hasLoadError;
  }
  public get contentBlocked(): boolean {
    return this.loading || this.blockView;
  }

  public connect(): Observable<void> {
    this.connectSub = this.options.loadTrigger$.subscribe((params) => {
      this.loadData(params);
    });
    return this.stateChanges$;
  }

  public disconnect(): void {
    this.connectSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }

  public setViewBlocked(value: boolean) {
    this.blockView = value;
    this.stateChanges$.next();
  }

  private loadData(params: TParams) {
    this.loadingSub.unsubscribe();
    this.loading = true;
    this.hasLoadError = false;
    this.exception = null;
    this.stateChanges$.next();

    let load$ = this.options.loadFn(params);
    if (!this.options.keepLoadStreamOpen) {
      load$ = load$.pipe(first());
    }
    this.loadingSub = load$.subscribe({
      next: () => {
        this.loading = false;
        this.stateChanges$.next();
      },
      error: (err) => {
        this.loading = false;
        this.hasLoadError = true;
        this.exception = {
          errorObject: err,
          alignCenter: true,
          icon: 'sentiment_very_dissatisfied',
        };
        this.stateChanges$.next();
      },
    });
  }
}
