/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UpdateAccountDto } from '../dto/update-account-dto';
import { UpdateAccountResultDto } from '../dto/update-account-result-dto';

export interface PostAccountUpdateParams {
  body: UpdateAccountDto;
}

export function httpPostAccountUpdate(http: HttpClient, data: PostAccountUpdateParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<UpdateAccountResultDto>;
export function httpPostAccountUpdate(http: HttpClient, data: PostAccountUpdateParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<UpdateAccountResultDto>>;
export function httpPostAccountUpdate(http: HttpClient, data: PostAccountUpdateParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<UpdateAccountResultDto>(`{placeholder}/Account/Update`, data.body, options as any);
};

export const urlPostAccountUpdate = (baseUrl: string, data: PostAccountUpdateParams) => {
  return `${baseUrl}/Account/Update`;
};
