import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const atLeastOneNumberValidator = () =>
  patternValidator(/\d/, { no_number: { message: $localize`:@@error.atLeastOneNumber:Der Wert muss mindestens eine Ziffer enthalten.` } });
export const atLeastOneCapitalCharValidator = () =>
  patternValidator(/[A-Z]/, {
    no_capital_char: { message: $localize`:@@error.atLeastOneCapitalChar:Der Wert muss mindestens einen Großbuchstaben enthalten.` },
  });
export const atLeastOneLowercaseCharValidator = () =>
  patternValidator(/[a-z]/, {
    no_lowercase_char: { message: $localize`:@@error.atLeastOneLowercaseChar:Der Wert muss mindestens einen Kleinbuchstaben enthalten.` },
  });
export const atLeastOneSpezialCharValidator = () =>
  patternValidator(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, {
    no_special_char: { message: $localize`:@@error.atLeastOneSpecialChar:Der Wert muss mindestens ein Sonderzeichen enthalten.` },
  });
export const passwordValidator = () =>
  Validators.compose([
    Validators.minLength(8),
    atLeastOneNumberValidator(),
    atLeastOneCapitalCharValidator(),
    atLeastOneLowercaseCharValidator(),
    atLeastOneSpezialCharValidator(),
  ]);

export const patternValidator = (pattern: RegExp, error: ValidationErrors): ValidatorFn => {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const valid = pattern.test(control.value);
    return valid ? null : error;
  };
};
