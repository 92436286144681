/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResetPasswordDto } from '../dto/reset-password-dto';
import { ResetPasswordResultDto } from '../dto/reset-password-result-dto';

export interface PostAccountResetPasswordParams {
  body: ResetPasswordDto;
}

export function httpPostAccountResetPassword(http: HttpClient, data: PostAccountResetPasswordParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<ResetPasswordResultDto>;
export function httpPostAccountResetPassword(http: HttpClient, data: PostAccountResetPasswordParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<ResetPasswordResultDto>>;
export function httpPostAccountResetPassword(http: HttpClient, data: PostAccountResetPasswordParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<ResetPasswordResultDto>(`{placeholder}/Account/ResetPassword`, data.body, options as any);
};

export const urlPostAccountResetPassword = (baseUrl: string, data: PostAccountResetPasswordParams) => {
  return `${baseUrl}/Account/ResetPassword`;
};
