/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginPasswordDto } from '../dto/login-password-dto';
import { LoginPasswordResultDto } from '../dto/login-password-result-dto';

export interface PostAccountLoginPasswordParams {
  body: LoginPasswordDto;
}

export function httpPostAccountLoginPassword(http: HttpClient, data: PostAccountLoginPasswordParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<LoginPasswordResultDto>;
export function httpPostAccountLoginPassword(http: HttpClient, data: PostAccountLoginPasswordParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<LoginPasswordResultDto>>;
export function httpPostAccountLoginPassword(http: HttpClient, data: PostAccountLoginPasswordParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<LoginPasswordResultDto>(`{placeholder}/Account/LoginPassword`, data.body, options as any);
};

export const urlPostAccountLoginPassword = (baseUrl: string, data: PostAccountLoginPasswordParams) => {
  return `${baseUrl}/Account/LoginPassword`;
};
