/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetAccountStatusResponseDto } from '../dto/get-account-status-response-dto';

export function httpGetBffAccountStatusGet(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<GetAccountStatusResponseDto>;
export function httpGetBffAccountStatusGet(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<GetAccountStatusResponseDto>>;
export function httpGetBffAccountStatusGet(http: HttpClient, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<GetAccountStatusResponseDto>(`{placeholder}/bff/account/status/get`, options as any);
};

export const urlGetBffAccountStatusGet = (baseUrl: string) => {
  return `${baseUrl}/bff/account/status/get`;
};
