/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResetTwoStepCodeResultDto } from '../dto/reset-two-step-code-result-dto';

export function httpPostAccountResetTwoStepCode(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<ResetTwoStepCodeResultDto>;
export function httpPostAccountResetTwoStepCode(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<ResetTwoStepCodeResultDto>>;
export function httpPostAccountResetTwoStepCode(http: HttpClient, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<ResetTwoStepCodeResultDto>(`{placeholder}/Account/ResetTwoStepCode`, undefined, options as any);
};

export const urlPostAccountResetTwoStepCode = (baseUrl: string) => {
  return `${baseUrl}/Account/ResetTwoStepCode`;
};
