/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toQueryString } from '../../api-helpers';
import { RedirectValidationDto } from '../dto/redirect-validation-dto';

export interface GetRedirectValidateRedirectUrlQueryParams {
  url?: string;
}

export interface GetRedirectValidateRedirectUrlParams {
  query: GetRedirectValidateRedirectUrlQueryParams;
}

export function httpGetRedirectValidateRedirectUrl(http: HttpClient, data: GetRedirectValidateRedirectUrlParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<RedirectValidationDto>;
export function httpGetRedirectValidateRedirectUrl(http: HttpClient, data: GetRedirectValidateRedirectUrlParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<RedirectValidationDto>>;
export function httpGetRedirectValidateRedirectUrl(http: HttpClient, data: GetRedirectValidateRedirectUrlParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<RedirectValidationDto>(`{placeholder}/Redirect/ValidateRedirectUrl?${toQueryString(data.query)}`, options as any);
};

export const urlGetRedirectValidateRedirectUrl = (baseUrl: string, data: GetRedirectValidateRedirectUrlParams) => {
  return `${baseUrl}/Redirect/ValidateRedirectUrl?${toQueryString(data.query)}`;
};
