/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogWrapperDataOut, DialogWrapperDataSource } from '@zvoove-sso/shared';
import { Observable } from 'rxjs';
import { ZvDialogWrapper } from '@zvoove/components/dialog-wrapper';

@Component({
  selector: 'zv-confirm-dialog',
  templateUrl: './confirm.dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ZvDialogWrapper],
})
export class ConfirmDialog {
  private dialogRef = inject(MatDialogRef<ConfirmDialog, DialogWrapperDataOut<unknown>>);
  public data = inject<ConfirmDialogDataIn>(MAT_DIALOG_DATA);

  public dwds = new DialogWrapperDataSource({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dialogRef: this.dialogRef,
    dialogTitle: this.data.title,
    actionFn: this.data.actionFn,
    btnConfigFn: (btns) => {
      if (this.data.okButtonText) {
        btns.action.label = this.data.okButtonText;
      }
    },
  });
}

export interface ConfirmDialogDataIn {
  title: string;
  content: string;
  actionFn: () => Observable<unknown>;
  okButtonText?: string;
}
