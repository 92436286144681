/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toQueryString } from '../../api-helpers';
import { ZvooveProblemDetails } from '../dto/zvoove-problem-details';

export interface GetBffAvdEntraUserIsSyncedQueryParams {
  userId: string;
}

export interface GetBffAvdEntraUserIsSyncedParams {
  query: GetBffAvdEntraUserIsSyncedQueryParams;
}

export function httpGetBffAvdEntraUserIsSynced(http: HttpClient, data: GetBffAvdEntraUserIsSyncedParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<boolean>;
export function httpGetBffAvdEntraUserIsSynced(http: HttpClient, data: GetBffAvdEntraUserIsSyncedParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<boolean>>;
export function httpGetBffAvdEntraUserIsSynced(http: HttpClient, data: GetBffAvdEntraUserIsSyncedParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<boolean>(`{placeholder}/bff/avd/entraUserIsSynced?${toQueryString(data.query)}`, options as any);
};

export const urlGetBffAvdEntraUserIsSynced = (baseUrl: string, data: GetBffAvdEntraUserIsSyncedParams) => {
  return `${baseUrl}/bff/avd/entraUserIsSynced?${toQueryString(data.query)}`;
};
