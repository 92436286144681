/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountDetailsForUpdateDto } from '../dto/account-details-for-update-dto';

export function httpGetAccountGetDetailsForUpdate(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<AccountDetailsForUpdateDto>;
export function httpGetAccountGetDetailsForUpdate(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<AccountDetailsForUpdateDto>>;
export function httpGetAccountGetDetailsForUpdate(http: HttpClient, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<AccountDetailsForUpdateDto>(`{placeholder}/Account/GetDetailsForUpdate`, options as any);
};

export const urlGetAccountGetDetailsForUpdate = (baseUrl: string) => {
  return `${baseUrl}/Account/GetDetailsForUpdate`;
};
