import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoginContextService } from '../login-context/login-context.service';
import { ZVOOVE_EMPLOYEE_ROLE } from '../login-context/roles';

export const forNonZvooveUsersOnlyGuard = () => {
  const auth = inject(LoginContextService);
  const router = inject(Router);

  if (auth.isLoggedIn()) {
    return !auth.hasGlobalRole(ZVOOVE_EMPLOYEE_ROLE) || router.parseUrl('/page-not-found'); 
  }

  return router.parseUrl('/');
};
