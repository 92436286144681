/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationRef } from '@angular/core';
import { LoginContextDto } from '@zvoove-sso/api';
import { GlobalErrorHandler } from '../error/global-error-handler';

export interface BootstrapOptions {
  configUrl: string;
  prod: boolean;
  bootstrapFunc: () => Promise<void | ApplicationRef>;
}

let loginContext: LoginContextDto = null!;
export const getInitialLoginContext = () => loginContext;

export function bootstrapAngular({ configUrl, bootstrapFunc }: BootstrapOptions) {
  (async () => {
    // bootstrapApplication(AppComponent, appConfig) must stay in main.ts otherwise lazyloading breaks.
    setProgress('Loading user context...');
    loginContext = await loadLoginContext(configUrl);
    await bootstrapFunc();
  })().catch((e) => setProgressError(e));

  function setProgress(message: string) {
    const node = document.getElementById('js-boot-message');
    if (node) {
      node.textContent = message;
    }
  }

  function setProgressError(err: any) {
    console.error('bootstrap error', err);
    const errorNode = document.getElementById('js-boot-error');
    if (errorNode) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      errorNode.textContent = err.message || err;
    }
    const errorHandler = new GlobalErrorHandler();
    errorHandler.handleError(err);
  }
}

async function loadLoginContext(baseUrl: string): Promise<LoginContextDto> {
  return (await (await fetch(`${baseUrl}/Account/GetLoginContext`, { credentials: 'include' })).json()) as LoginContextDto;
}
