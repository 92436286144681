import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IZvButton, IZvException } from '@zvoove/components/core';
import { IZvDialogWrapperDataSource } from '@zvoove/components/dialog-wrapper';
import { Observable, Subject, Subscription, merge } from 'rxjs';
import { first } from 'rxjs/operators';

export const enum DialogWrapperAction {
  action = 1,
  cancel = 2,
}

export interface DialogWrapperDataOut<TDataOut> {
  action: DialogWrapperAction;
  saveResult: TDataOut | undefined;
}

export interface DialogWrapperDataSourceOptions<TDataOut, TDataIn = never> {
  dialogRef: MatDialogRef<unknown, DialogWrapperDataOut<TDataOut>>;
  dialogTitle?: string;
  btnConfigFn?: (btns: { action: IZvButton; cancel: IZvButton }) => void;
  loadFn?: () => Observable<TDataIn>;
  actionFn: (data: TDataIn | null, options: { progressCallback: (progress: number) => void }) => Observable<TDataOut>;
  form?: FormGroup;
}

export class DialogWrapperDataSource<TDataOut, TDataIn = never> implements IZvDialogWrapperDataSource {
  public buttons: IZvButton[] = [];
  public loadedData: TDataIn | null = null;
  public exception: IZvException | null = null;
  public progress: number | null = null;
  public defaultButtons = {
    action: {
      label: $localize`:@@general.ok:Ok`,
      type: 'raised',
      color: 'primary',
      disabled: () => this.contentBlocked || (this.form && !this.form.valid) || this.defaultOkDisabled,
      click: () => this.confirm(),
    } as IZvButton,
    cancel: {
      label: $localize`:@@general.cancel:Abbrechen`,
      type: 'stroked',
      color: undefined,
      disabled: () => false,
      click: () => this.close(),
    } as IZvButton,
  };

  private loading = false;
  private hasLoadError = false;
  private saving = false;
  private blockView = false;
  private defaultOkDisabled = false;
  private stateChanges$ = new Subject<void>();
  private loadingSubscription = Subscription.EMPTY;
  private connectSubscription = Subscription.EMPTY;
  private formSubscription = Subscription.EMPTY;

  constructor(private options: DialogWrapperDataSourceOptions<TDataOut, TDataIn>) {
    if (this.options.btnConfigFn) {
      this.options.btnConfigFn(this.defaultButtons);
    }
    if (this.options.form) {
      this.formSubscription = merge(this.options.form.valueChanges, this.options.form.statusChanges).subscribe(() => {
        this.stateChanges$.next();
      });
    }
    this.buttons = [this.defaultButtons.action, this.defaultButtons.cancel];
  }

  public get dialogTitle(): string | null {
    return this.options.dialogTitle ?? null;
  }
  public get form(): FormGroup | null {
    return this.options.form ?? null;
  }
  public get contentVisible(): boolean {
    return !this.hasLoadError;
  }
  public get contentBlocked(): boolean {
    return this.loading || this.saving || this.blockView;
  }

  public connect(): Observable<void> {
    this.loadData();
    return this.stateChanges$;
  }

  public disconnect(): void {
    this.loadingSubscription.unsubscribe();
    this.connectSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
    if (this.stateChanges$) {
      this.stateChanges$.complete();
      this.stateChanges$ = new Subject<void>();
    }
  }

  public setDefaultOkDisabled(disabled: boolean): void {
    this.defaultOkDisabled = disabled;
    if (this.stateChanges$) {
      this.stateChanges$.next();
    }
  }

  public setViewBlocked(value: boolean): void {
    this.blockView = value;
    this.stateChanges$.next();
  }

  public confirm(): void {
    this.saving = true;
    this.exception = null;
    this.progress = null;
    this.stateChanges$.next();

    this.options
      .actionFn(this.loadedData, {
        progressCallback: (percent) => {
          this.progress = percent;
          this.stateChanges$.next();
        },
      })
      .pipe(first())
      .subscribe({
        next: (saveResult) => {
          this.saving = false;
          this.progress = null;
          this.setError(null);
          this.closeDialog(DialogWrapperAction.action, saveResult);
        },
        error: (err) => {
          this.saving = false;
          this.progress = null;
          this.setError(err);
          this.stateChanges$.next();
        },
      });
  }

  public close(): void {
    this.closeDialog(DialogWrapperAction.cancel);
  }

  private loadData(): void {
    this.loadingSubscription.unsubscribe();
    this.loadedData = null;
    this.hasLoadError = false;
    this.exception = null;
    this.stateChanges$.next();

    if (!this.options.loadFn) {
      return;
    }

    this.loading = true;
    this.loadingSubscription = this.options
      .loadFn()
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.loadedData = data;
          this.loading = false;
          this.stateChanges$.next();
        },
        error: (err) => {
          this.loading = false;
          this.hasLoadError = true;
          this.setError(err);
          this.stateChanges$.next();
        },
      });
  }

  private closeDialog(actionResult: DialogWrapperAction, saveResult?: TDataOut): void {
    this.options.dialogRef.close({
      action: actionResult,
      saveResult: saveResult,
    });
  }

  private setError(err: unknown): void {
    this.exception = {
      errorObject: err,
    };
  }
}
