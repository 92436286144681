import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { httpGetRedirectValidateRedirectUrl } from '@zvoove-sso/api';
import { first } from 'rxjs/operators';
import { LoginContextService } from '@zvoove-sso/shared';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(
    private http: HttpClient,
    private auth: LoginContextService
  ) {}

  private setWindowLocation(url: string): void {
    window.location.href = url;
  }

  public navigateToReturnUrl(returnUrl: string) {
    httpGetRedirectValidateRedirectUrl(this.http, { query: { url: returnUrl } })
      .pipe(first())
      .subscribe((res) => {
        this.setWindowLocation(res.valid ? returnUrl : this.auth.defaultRedirectUrl());
      });
  }

  public redirect(url: string) {
    this.setWindowLocation(url);
  }
}
