/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccountRegisterCompleteRequestDto } from '../dto/account-register-complete-request-dto';
import { AccountRegisterCompleteResponseDto } from '../dto/account-register-complete-response-dto';
import { HttpValidationProblemDetails } from '../dto/http-validation-problem-details';

export interface PostBffAccountRegisterCompleteParams {
  body: AccountRegisterCompleteRequestDto;
}

export function httpPostBffAccountRegisterComplete(http: HttpClient, data: PostBffAccountRegisterCompleteParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<AccountRegisterCompleteResponseDto>;
export function httpPostBffAccountRegisterComplete(http: HttpClient, data: PostBffAccountRegisterCompleteParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<AccountRegisterCompleteResponseDto>>;
export function httpPostBffAccountRegisterComplete(http: HttpClient, data: PostBffAccountRegisterCompleteParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<AccountRegisterCompleteResponseDto>(`{placeholder}/bff/account/register/complete`, data.body, options as any);
};

export const urlPostBffAccountRegisterComplete = (baseUrl: string, data: PostBffAccountRegisterCompleteParams) => {
  return `${baseUrl}/bff/account/register/complete`;
};
