import { HttpInterceptorFn, HttpXsrfTokenExtractor } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';

const mappingPrefix = '{placeholder}';
export const ssoHttpInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.url.startsWith(mappingPrefix)) {
    return next(req);
  }
  let headers = req.headers;

  // load token
  const xsrfToken = inject(HttpXsrfTokenExtractor).getToken();

  if (xsrfToken != null) {
    headers = req.headers.set('SSO-XSRF-TOKEN', xsrfToken);
  }

  req = req.clone({
    url: environment.backendBaseUrl + req.url.substring(mappingPrefix.length),
    headers: headers,
    withCredentials: true,
  });

  return next(req);
};
