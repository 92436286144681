import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { ErrorHandler, inject } from '@angular/core';
import { tap } from 'rxjs';

export interface StatusCodeInterceptorOptions {
  statusCodesToLog: number[];
}

export const statusCodeInterceptor = (options: StatusCodeInterceptorOptions) => {
  return (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const errorHandler = inject(ErrorHandler);
    return next(req).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && options.statusCodesToLog.includes(error.status)) {
            errorHandler.handleError(error);
          }
        },
      })
    );
  };
};
