/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BaseZvFormService, IZvFormError, IZvFormErrorData } from '@zvoove/components/form-base';
import { Observable, of } from 'rxjs';
import { convertFilesize } from '../function/file';

const dateFormatterMap = new Map<string, Intl.DateTimeFormat>();

const mapErrorToText = (error: IZvFormErrorData, localeId: string): string => {
  const getDateFormatter = () => {
    let formater = dateFormatterMap.get(localeId);
    if (!formater) {
      formater = new Intl.DateTimeFormat(localeId, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      dateFormatterMap.set(localeId, formater);
    }
    return formater;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorValue = error.errorValue as any;
  if (errorValue?.message) {
    return errorValue.message;
  }

  switch (error.errorKey) {
    case 'email':
      return $localize`:@@error.email:Bitte geben Sie eine gültige E-Mail-Adresse an.`;
    case 'max':
      return $localize`:@@error.max:Der Wert darf nicht größer sein als ${errorValue.max}.`;
    case 'maxlength':
      return $localize`:@@error.maxLength:Der Wert darf maximal ${errorValue.requiredLength} Zeichen lang sein.`;
    case 'min':
      return $localize`:@@error.min:Der Wert darf nicht kleiner sein als ${errorValue.min}.`;
    case 'minlength':
      return $localize`:@@error.minLength:Der Wert muss mindestens ${errorValue.requiredLength} Zeichen lang sein.`;
    case 'pattern':
      return $localize`:@@error.pattern:Der Wert muss folgendem Format entsprechen: ${errorValue.example}`;
    case 'maxFileSize':
      return $localize`:@@error.maxFileSize:Die Datei darf nicht größer sein als ${convertFilesize(errorValue.maxFileSize, 'B')}`;
    case 'acceptableFileExtensions':
      return $localize`:@@error.acceptableFileExtensions:Folgende Dateitypen sind erlaubt: ${errorValue.acceptableExtensions.join(', ')}`;
    case 'matDatepickerParse':
      return $localize`:@@error.matDatepickerParse:Das Datum muss folgendem Format entsprechen: TT.MM.JJJJ`;
    case 'matDatepickerFilter':
      return $localize`:@@error.matDatepickerFilter:Das ausgewählte Datum ist nicht erlaubt.`;
    case 'matDatepickerMin':
      return $localize`:@@error.matDatepickerMin:Das Datum muss nach ${getDateFormatter().format(errorValue.min)} sein.`;
    case 'matDatepickerMax':
      return $localize`:@@error.matDatepickerMax:Das Datum muss vor ${getDateFormatter().format(errorValue.max)} sein.`;
    case 'zvTimeInputParse':
      return $localize`:@@error.zvDateTimeInputState:Die Uhrzeit muss folgendem Format entsprechen: hh:mm`;
    case 'zvDateTimeInputState':
      return $localize`:@@error.zvTimeInputParse:Das Datum muss folgendem Format entsprechen: TT.MM.JJJJ hh:mm`;
    default:
      return $localize`:@@error.unknownKey:Bitte geben Sie einen gültigen Wert an. Key: ${error.errorKey}`;
  }
};

@Injectable({ providedIn: 'root' })
export class FormService extends BaseZvFormService {
  constructor(@Inject(LOCALE_ID) private localeId: string) {
    super();
    this.options.includeControlsDefault = true;
  }

  public override filterErrors(errorData: IZvFormErrorData[], _: boolean, source: 'form' | 'control'): Observable<IZvFormErrorData[]> {
    const requiredErrorExists = errorData.some((error) => error.errorKey === 'required');
    const controlErrorExists = errorData.some((error) => error.isControl);
    let errorDataCopy = [...errorData];
    errorDataCopy = errorDataCopy
      .filter((error) => error.errorKey !== 'required')
      .filter((error) => !error.errorKey.startsWith('groupErrorMember'));

    if (source === 'form') {
      errorDataCopy = errorDataCopy.filter((x) => !x.isControl);
      if (requiredErrorExists || controlErrorExists) {
        errorDataCopy.push({
          controlPath: '',
          errorKey: 'some_controls_required',
          errorValue: {
            message: $localize`:@@shared.form.fillRequiredFields:Bitte alle Pflichtfelder gültig ausfüllen.`,
          },
          isControl: false,
        });
      }
    }

    return of(errorDataCopy);
  }

  getLabel(): Observable<string> | null {
    return null;
  }

  protected mapDataToError(errorData: IZvFormErrorData[]): Observable<IZvFormError[]> {
    if (!errorData.length) {
      return of([]);
    }
    return of(errorData.map((error) => ({ data: error, errorText: mapErrorToText(error, this.localeId) })));
  }
}
