import { from, Observable } from 'rxjs';

export const structuralClone = <T>(obj: T): Observable<T> =>
  from(
    new Promise<T>((resolve) => {
      const { port1, port2 } = new MessageChannel();
      port2.onmessage = (ev) => resolve(ev.data as unknown as T);
      port1.postMessage(obj);
    })
  );

export const toDate = (currentValue: Date | string): Date | null => {
  if (currentValue instanceof Date) {
    return currentValue;
  }
  if (!currentValue) {
    return null;
  }
  const newValue = new Date(currentValue);
  if (isNaN(newValue.getTime())) {
    throw Error(`Invalid Date '${currentValue}'`);
  }

  return new Date(currentValue);
};

// der Date-Typ ist nicht relevant, da wir einen Klon des zu sendenden Objekts machen.
// Format ist hier wichtig, da am Tenant ein ParseExact auf dieses Format gemacht wird.
export const toDateJsonString = (date: Date | null | undefined): string | null => {
  if (!date || !(date instanceof Date)) {
    return null;
  }

  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
};

export const toQueryString = <T extends { hasOwnProperty: (k: string) => boolean }>(queryParameter: T) => {
  const parts: string[] = [];
  for (const key in queryParameter) {
    if (!Object.prototype.hasOwnProperty.call(queryParameter, key)) {
      continue;
    }

    const value = queryParameter[key];
    if (value === null || value === undefined) {
      parts.push(key + '=');
    } else if (value instanceof Date) {
      parts.push(key + '=' + encodeURIComponent(value.toISOString()));
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      parts.push(key + '=' + value);
    } else if (typeof value === 'string') {
      parts.push(key + '=' + encodeURIComponent(value));
    } else if (Array.isArray(value)) {
      const arrayQueryString = arrayToQueryString(key, value);
      if (arrayQueryString !== '') {
        parts.push(arrayQueryString);
      }
    } else {
      throw new Error('cannot create querystring for value: ' + JSON.stringify(value));
    }
  }

  return parts.join('&');
};

const arrayToQueryString = (paramName: string, items: (string | number | boolean)[]) => {
  if (!items || items.length === 0) {
    return '';
  }

  return paramName + '=' + items.map((item) => encodeURIComponent(item)).join('&' + paramName + '=');
};
