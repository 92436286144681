/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ForgotPasswordDto } from '../dto/forgot-password-dto';

export interface PostAccountForgotPasswordParams {
  body: ForgotPasswordDto;
}

export function httpPostAccountForgotPassword(http: HttpClient, data: PostAccountForgotPasswordParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<void>;
export function httpPostAccountForgotPassword(http: HttpClient, data: PostAccountForgotPasswordParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<void>>;
export function httpPostAccountForgotPassword(http: HttpClient, data: PostAccountForgotPasswordParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<void>(`{placeholder}/Account/ForgotPassword`, data.body, options as any);
};

export const urlPostAccountForgotPassword = (baseUrl: string, data: PostAccountForgotPasswordParams) => {
  return `${baseUrl}/Account/ForgotPassword`;
};
