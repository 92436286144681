/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginDto } from '../dto/login-dto';
import { LoginResultDto } from '../dto/login-result-dto';

export interface PostAccountLoginParams {
  body: LoginDto;
}

export function httpPostAccountLogin(http: HttpClient, data: PostAccountLoginParams, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<LoginResultDto>;
export function httpPostAccountLogin(http: HttpClient, data: PostAccountLoginParams, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<LoginResultDto>>;
export function httpPostAccountLogin(http: HttpClient, data: PostAccountLoginParams, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.post<LoginResultDto>(`{placeholder}/Account/Login`, data.body, options as any);
};

export const urlPostAccountLogin = (baseUrl: string, data: PostAccountLoginParams) => {
  return `${baseUrl}/Account/Login`;
};
